<template>
  <div class="faceDelay">
    <div class="container" id="link">
      <Practice v-if="isPractice"></Practice>
      <ul class="nodes">
        <li
          :class="current >= index ? 'nodeItem connection' : 'nodeItem'"
          v-for="(item, index) in nodeList"
          :key="'left' + index"
          :id="item.nodeId"
          :style="{
            left: item.x + 'px',
            top: item.y + 'px',
          }"
        >
          <div
            class="nodeName"
            :style="{
              'font-family': item.name == 'I' ? 'Ebrima' : 'SYHeiBold',
              'font-weight': item.name == 'I' ? 'bold' : '',
            }"
          >
            {{ item.name }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import { saveSubjectiveAns } from '@/api/index';
import Practice from '@/components/practice.vue';
//数据
let practiceData = [
  { name: '1', nodeId: 'node1', id: 1, x: 108, y: 149 },
  { name: 'A', nodeId: 'node2', id: 2, x: 237, y: 417 },
  { name: '2', nodeId: 'node3', id: 3, x: 310, y: 242 },
  { name: 'B', nodeId: 'node4', id: 4, x: 507, y: 86 },
  { name: '3', nodeId: 'node5', id: 5, x: 800, y: 15 },
  { name: 'C', nodeId: 'node6', id: 6, x: 707, y: 186 },
  { name: '4', nodeId: 'node7', id: 7, x: 850, y: 444 },
  { name: 'D', nodeId: 'node8', id: 8, x: 530, y: 325 },
];
let formalData = [
  [
    { name: '1', nodeId: 'node1', id: 17, x: 673, y: 259 },
    { name: 'A', nodeId: 'node2', id: 23, x: 902, y: 134 },
    { name: '2', nodeId: 'node3', id: 21, x: 865, y: 224 },
    { name: 'B', nodeId: 'node4', id: 20, x: 804, y: 308 },
    { name: '3', nodeId: 'node5', id: 22, x: 865, y: 430 },
    { name: 'C', nodeId: 'node6', id: 24, x: 969, y: 27 },
    { name: '4', nodeId: 'node7', id: 25, x: 957, y: 535 },
    { name: 'D', nodeId: 'node8', id: 19, x: 729, y: 487 },
    { name: '5', nodeId: 'node9', id: 14, x: 538, y: 580 },
    { name: 'E', nodeId: 'node10', id: 11, x: 393, y: 387 },
    { name: '6', nodeId: 'node11', id: 12, x: 485, y: 300 },
    { name: 'F', nodeId: 'node12', id: 10, x: 353, y: 214 },
    { name: '7', nodeId: 'node13', id: 8, x: 217, y: 498 },
    { name: 'G', nodeId: 'node14', id: 2, x: 53, y: 486 },
    { name: '8', nodeId: 'node15', id: 7, x: 200, y: 375 },
    { name: 'H', nodeId: 'node16', id: 1, x: -9, y: 294 },
    { name: '9', nodeId: 'node17', id: 4, x: 97, y: 184 },
    { name: 'I', nodeId: 'node18', id: 6, x: 199, y: 260 },
    { name: '10', nodeId: 'node19', id: 5, x: 181, y: 100 },
    { name: 'J', nodeId: 'node20', id: 3, x: 102, y: 20 },
    { name: '11', nodeId: 'node21', id: 9, x: 325, y: 60 },
    { name: 'K', nodeId: 'node22', id: 13, x: 516, y: 117 },
    { name: '12', nodeId: 'node23', id: 18, x: 737, y: 67 },
    { name: 'L', nodeId: 'node24', id: 16, x: 636, y: 161 },
    { name: '13', nodeId: 'node25', id: 15, x: 562, y: 371 },
  ],
  [
    { name: '1', nodeId: 'node1', id: 17, x: 673, y: 259 },
    { name: 'A', nodeId: 'node2', id: 18, x: 737, y: 67 },
    { name: '2', nodeId: 'node3', id: 24, x: 969, y: 27 },
    { name: 'B', nodeId: 'node4', id: 23, x: 902, y: 134 },
    { name: '3', nodeId: 'node5', id: 25, x: 957, y: 535 },
    { name: 'C', nodeId: 'node6', id: 22, x: 865, y: 430 },
    { name: '4', nodeId: 'node7', id: 20, x: 804, y: 308 },
    { name: 'D', nodeId: 'node8', id: 21, x: 865, y: 224 },
    { name: '5', nodeId: 'node9', id: 15, x: 562, y: 371 },
    { name: 'E', nodeId: 'node10', id: 19, x: 729, y: 487 },
    { name: '6', nodeId: 'node11', id: 14, x: 538, y: 580 },
    { name: 'F', nodeId: 'node12', id: 11, x: 393, y: 387 },
    { name: '7', nodeId: 'node13', id: 8, x: 217, y: 498 },
    { name: 'G', nodeId: 'node14', id: 7, x: 200, y: 375 },
    { name: '8', nodeId: 'node15', id: 2, x: 53, y: 486 },
    { name: 'H', nodeId: 'node16', id: 1, x: -9, y: 294 },
    { name: '9', nodeId: 'node17', id: 6, x: 199, y: 260 },
    { name: 'I', nodeId: 'node18', id: 10, x: 353, y: 214 },
    { name: '10', nodeId: 'node19', id: 4, x: 97, y: 184 },
    { name: 'J', nodeId: 'node20', id: 5, x: 181, y: 100 },
    { name: '11', nodeId: 'node21', id: 3, x: 102, y: 20 },
    { name: 'K', nodeId: 'node22', id: 9, x: 325, y: 60 },
    { name: '12', nodeId: 'node23', id: 13, x: 516, y: 117 },
    { name: 'L', nodeId: 'node24', id: 16, x: 636, y: 161 },
    { name: '13', nodeId: 'node25', id: 12, x: 485, y: 300 },
  ],
  [
    { name: '1', nodeId: 'node1', id: 9, x: 325, y: 60 },
    { name: 'A', nodeId: 'node2', id: 3, x: 102, y: 20 },
    { name: '2', nodeId: 'node3', id: 5, x: 181, y: 100 },
    { name: 'B', nodeId: 'node4', id: 4, x: 97, y: 184 },
    { name: '3', nodeId: 'node5', id: 10, x: 353, y: 214 },
    { name: 'C', nodeId: 'node6', id: 11, x: 393, y: 387 },
    { name: '4', nodeId: 'node7', id: 12, x: 485, y: 300 },
    { name: 'D', nodeId: 'node8', id: 13, x: 516, y: 117 },
    { name: '5', nodeId: 'node9', id: 16, x: 636, y: 161 },
    { name: 'E', nodeId: 'node10', id: 18, x: 737, y: 67 },
    { name: '6', nodeId: 'node11', id: 24, x: 969, y: 27 },
    { name: 'F', nodeId: 'node12', id: 17, x: 673, y: 259 },
    { name: '7', nodeId: 'node13', id: 23, x: 902, y: 134 },
    { name: 'G', nodeId: 'node14', id: 21, x: 865, y: 224 },
    { name: '8', nodeId: 'node15', id: 25, x: 957, y: 535 },
    { name: 'H', nodeId: 'node16', id: 22, x: 865, y: 430 },
    { name: '9', nodeId: 'node17', id: 19, x: 729, y: 487 },
    { name: 'I', nodeId: 'node18', id: 14, x: 538, y: 580 },
    { name: '10', nodeId: 'node19', id: 20, x: 804, y: 308 },
    { name: 'J', nodeId: 'node20', id: 15, x: 562, y: 371 },
    { name: '11', nodeId: 'node21', id: 8, x: 217, y: 498 },
    { name: 'K', nodeId: 'node22', id: 2, x: 53, y: 486 },
    { name: '12', nodeId: 'node23', id: 1, x: -9, y: 294 },
    { name: 'L', nodeId: 'node24', id: 7, x: 200, y: 375 },
    { name: '13', nodeId: 'node25', id: 6, x: 199, y: 260 },
  ],
  [
    { name: '1', nodeId: 'node1', id: 14, x: 538, y: 580 },
    { name: 'A', nodeId: 'node2', id: 19, x: 729, y: 487 },
    { name: '2', nodeId: 'node3', id: 25, x: 957, y: 535 },
    { name: 'B', nodeId: 'node4', id: 23, x: 902, y: 134 },
    { name: '3', nodeId: 'node5', id: 24, x: 969, y: 27 },
    { name: 'C', nodeId: 'node6', id: 18, x: 737, y: 67 },
    { name: '4', nodeId: 'node7', id: 21, x: 865, y: 224 },
    { name: 'D', nodeId: 'node8', id: 22, x: 865, y: 430 },
    { name: '5', nodeId: 'node9', id: 20, x: 804, y: 308 },
    { name: 'E', nodeId: 'node10', id: 16, x: 685, y: 161 },
    { name: '6', nodeId: 'node11', id: 13, x: 500, y: 117 },
    { name: 'F', nodeId: 'node12', id: 17, x: 655, y: 285 },
    { name: '7', nodeId: 'node13', id: 15, x: 562, y: 371 },
    { name: 'G', nodeId: 'node14', id: 11, x: 393, y: 387 },
    { name: '8', nodeId: 'node15', id: 7, x: 200, y: 375 },
    { name: 'H', nodeId: 'node16', id: 8, x: 217, y: 498 },
    { name: '9', nodeId: 'node17', id: 2, x: 53, y: 486 },
    { name: 'I', nodeId: 'node18', id: 6, x: 199, y: 260 },
    { name: '10', nodeId: 'node19', id: 1, x: -9, y: 294 },
    { name: 'J', nodeId: 'node20', id: 4, x: 97, y: 184 },
    { name: '11', nodeId: 'node21', id: 10, x: 353, y: 214 },
    { name: 'K', nodeId: 'node22', id: 5, x: 181, y: 100 },
    { name: '12', nodeId: 'node23', id: 3, x: 102, y: 20 },
    { name: 'L', nodeId: 'node24', id: 9, x: 325, y: 60 },
    { name: '13', nodeId: 'node25', id: 12, x: 485, y: 300 },
  ],
  [
    { name: '1', nodeId: 'node1', id: 4, x: 97, y: 184 },
    { name: 'A', nodeId: 'node2', id: 5, x: 181, y: 100 },
    { name: '2', nodeId: 'node3', id: 3, x: 102, y: 20 },
    { name: 'B', nodeId: 'node4', id: 9, x: 325, y: 60 },
    { name: '3', nodeId: 'node5', id: 6, x: 199, y: 260 },
    { name: 'C', nodeId: 'node6', id: 1, x: -9, y: 294 },
    { name: '4', nodeId: 'node7', id: 2, x: 53, y: 486 },
    { name: 'D', nodeId: 'node8', id: 14, x: 538, y: 580 },
    { name: '5', nodeId: 'node9', id: 8, x: 290, y: 475 },
    { name: 'E', nodeId: 'node10', id: 19, x: 729, y: 487 },
    { name: '6', nodeId: 'node11', id: 7, x: 200, y: 375 },
    { name: 'F', nodeId: 'node12', id: 10, x: 353, y: 214 },
    { name: '7', nodeId: 'node13', id: 11, x: 393, y: 360 },
    { name: 'G', nodeId: 'node14', id: 15, x: 562, y: 371 },
    { name: '8', nodeId: 'node15', id: 17, x: 673, y: 259 },
    { name: 'H', nodeId: 'node16', id: 20, x: 804, y: 308 },
    { name: '9', nodeId: 'node17', id: 22, x: 865, y: 430 },
    { name: 'I', nodeId: 'node18', id: 25, x: 957, y: 535 },
    { name: '10', nodeId: 'node19', id: 21, x: 865, y: 224 },
    { name: 'J', nodeId: 'node20', id: 16, x: 636, y: 161 },
    { name: '11', nodeId: 'node21', id: 18, x: 750, y: 105 },
    { name: 'K', nodeId: 'node22', id: 23, x: 902, y: 134 },
    { name: '12', nodeId: 'node23', id: 24, x: 939, y: 17 },
    { name: 'L', nodeId: 'node24', id: 13, x: 516, y: 117 },
    { name: '13', nodeId: 'node25', id: 12, x: 485, y: 300 },
  ],
];
export default {
  name: 'linkElementModal',
  data() {
    return {
      isPractice: true, //是否练习题
      jsPlumb: null, // 缓存实例化的jsplumb对象
      current: 0, //当前进度下标
      errorCount: 0, //错误数量
      totalTime: 0, //总用时
      startTimeTotal: 0, //起始时间
      endTimeTotal: 0, //结束时间
      original: [], //原始操作数据
      nodeList: [], //数据
      siteArr: [
        { x: 510, y: 20, id: 1 },
        { x: 766, y: 5, id: 2 },
        { x: 148, y: 5, id: 3 },
        { x: 25, y: 480, id: 4 },
        { x: 268, y: 45, id: 5 },
        { x: 889, y: 55, id: 6 },
        { x: 389, y: 20, id: 7 },
        { x: 637, y: 50, id: 8 },
        { x: 474, y: 110, id: 9 },
        { x: 345, y: 130, id: 10 },
        { x: 10, y: 145, id: 11 },
        { x: 727, y: 118, id: 12 },
        { x: 840, y: 151, id: 13 },
        { x: 127, y: 125, id: 14 },
        { x: 578, y: 155, id: 15 },
        { x: 410, y: 210, id: 16 },
        { x: 231, y: 170, id: 17 },
        { x: 645, y: 235, id: 18 },
        { x: 760, y: 245, id: 19 },
        { x: 41, y: 255, id: 20 },
        { x: 900, y: 265, id: 21 },
        { x: 528, y: 250, id: 22 },
        { x: 222, y: 290, id: 23 },
        { x: 349, y: 305, id: 24 },
        { x: 119, y: 320, id: 25 },
        { x: 722, y: 345, id: 26 },
        { x: 850, y: 355, id: 27 },
        { x: 450, y: 360, id: 28 },
        { x: 15, y: 365, id: 29 },
        { x: 600, y: 360, id: 30 },
        { x: 289, y: 394, id: 31 },
        { x: 141, y: 430, id: 32 },
        { x: 795, y: 438, id: 33 },
        { x: 579, y: 450, id: 34 },
        { x: 38, y: 30, id: 35 },
        { x: 400, y: 455, id: 36 },
        { x: 234, y: 495, id: 37 },
        { x: 494, y: 515, id: 38 },
        { x: 898, y: 490, id: 39 },
        { x: 691, y: 500, id: 40 },
      ],
      timer1: null,
      timer2: null,
      sub: null,
      formalData: [],
    };
  },
  components: {
    Practice,
  },
  methods: {
    //抽取随机位置
    getRandomArr(arr, count) {
      let shuffled = arr.slice(0),
        i = arr.length,
        min = i - count,
        temp,
        index;
      while (i-- > min) {
        //打乱数组
        index = Math.floor((i + 1) * Math.random());
        temp = shuffled[index];
        shuffled[index] = shuffled[i];
        shuffled[i] = temp;
      }
      return shuffled.slice(min);
    },
    //连接交互事件
    showPlumb() {
      this.jsPlumb = this.$jsPlumb.getInstance({
        Container: 'link', // 选择器id
        EndpointStyle: { radius: 0.11, fill: '#12CE6A' }, // 端点样式
        PaintStyle: { stroke: '#12CE6A', strokeWidth: 5 }, // 绘画样式，默认8px线宽  #456
        HoverPaintStyle: { stroke: '#12CE6A', strokeWidth: 3 }, // 默认悬停样式  默认为null
        ConnectionOverlays: [
          // 此处可以设置所有箭头的样式
          // [
          //   'Arrow',
          //   {
          //     // 设置参数可以参考中文文档
          //     location: 1,
          //     length: 12,
          //     paintStyle: {
          //       stroke: '#12CE6A',
          //       fill: '#12CE6A',
          //     },
          //   },
          // ],
        ],
        Connector: ['Straight'], // 要使用的默认连接器的类型：直线，折线，曲线等
        DrapOptions: { cursor: 'crosshair', zIndex: 2000 },
      });

      this.jsPlumb.batch(() => {
        for (let i = 0; i < this.nodeList.length; i++) {
          if (i == this.current) {
            this.initLeaf(this.nodeList[i].nodeId, 'source');
          } else {
            this.initLeaf(this.nodeList[i].nodeId, 'target');
          }
        }
      });
      this.setjsPlumb(true, true);

      //点击连线
      // this.jsPlumb.bind('click', (conn, originalEvent) => {
      //   console.log(conn, originalEvent);
      // });

      //判断是否建立连线
      this.jsPlumb.bind('beforeDrop', (conn) => {
        const sourceId = conn.sourceId.substring(4);
        const targetId = conn.targetId.substring(4);
        //起点
        let source = this.nodeList.filter(
          (item) => conn.sourceId == item.nodeId
        )[0].name;
        //终点 用户答案
        let answer = this.nodeList.filter(
          (item) => conn.targetId == item.nodeId
        )[0].name;
        //正确答案
        let correct_answer = '';
        this.nodeList.forEach((item, index) => {
          if (item.name == source) {
            correct_answer = this.nodeList[index + 1].name;
          }
        });
        if (targetId - sourceId == 1) {
          this.original.push({
            source: source,
            answer: answer,
            correct_answer: correct_answer,
            correct_type: 1,
          });
          // console.log(JSON.stringify(this.original));
          //连接正确
          return true;
        } else {
          //连接错误
          this.errorCount++;
          this.original.push({
            source: source,
            answer: answer,
            correct_answer: correct_answer,
            correct_type: 0,
          });
          // console.log(JSON.stringify(this.original));
          $('#' + conn.targetId).addClass('error');
          setTimeout(() => {
            $('#' + conn.targetId).removeClass('error');
          }, 1000);
          return false;
        }
      });
      //连线时触发
      this.jsPlumb.bind('connection', () => {
        this.current++;
        this.initLeaf(this.nodeList[this.current].nodeId, 'source');
        if (this.current >= this.nodeList.length - 1) {
          //结束
          this.finish();
        }
      });

      //右键触发
      // this.jsPlumb.bind('contextmenu', (conn, originalEvent) => {
      //   console.log(conn, originalEvent);
      // });
    },
    //初始化规则使其可以连线、拖拽
    initLeaf(id, type) {
      const ins = this.jsPlumb;
      const elem = document.getElementById(id);
      if (type === 'source') {
        ins.makeSource(elem, {
          anchor: 'Continuous',
          allowLoopback: false, //允许回连
          maxConnections: 1, //最大连接数（-1表示不限制）
        });
      } else if (type === 'target') {
        ins.makeTarget(elem, {
          anchor: 'Continuous',
          allowLoopback: false,
          maxConnections: 1,
        });
      }
    },
    setjsPlumb(sourceFlag, targetFlag) {
      const source = document.getElementsByName('source');
      const target = document.getElementsByName('target');
      this.jsPlumb.setSourceEnabled(source, sourceFlag);
      this.jsPlumb.setTargetEnabled(target, targetFlag);
      this.jsPlumb.setDraggable(source, false); // 是否支持拖拽
      this.jsPlumb.setDraggable(target, false); // 是否支持拖拽
    },
    // 结束
    finish() {
      this.endTimeTotal = this.$util.getTimestamp();
      this.totalTime = this.endTimeTotal - this.startTimeTotal;
      if (this.isPractice) {
        //练习题
        // if (this.totalTime > 25000 - 1000) {
          this.$router.replace({
            path: '/practiceEnd',
            query: {
              source: 'faceDelay',
            },
          });
        // } else {
        //   this.$router.replace({
        //     path: '/relax',
        //     query: {
        //       time: 25000 - this.totalTime,
        //       path: '/practiceEnd?source=faceDelay',
        //     },
        //   });
        // }
      } else {
        let query = {
          fmUserId: this.$util.getStorage('fmUserId'),
          fmProductQuestionId: '2',
          quesNum: '2-2',
          totalTime: this.totalTime > 160000 ? 160000 : this.totalTime,
          data1Name: 'errorCount',
          data1: this.errorCount,
          data2Name: 'sort',
          data2: this.sub,
          dataJason: JSON.stringify(this.original),
          remark:
            'data1:错误数量；data2:连接方案标识；source：起始点；answer：用户结束点；correct_answer：正确结束点；correct_type：是否正确，1正确 0错误；',
        };
        saveSubjectiveAns(query)
          .then((res) => {
            if (res) {
              //大题编号
              sessionStorage.setItem(
                'fmProductQuestionId',
                res.fmProductQuestionId
              );
              //小题编号
              sessionStorage.setItem('quesNum', res.quesNum);
              //是否完成
              sessionStorage.setItem('isFinished', res.isFinished);
              // if (this.totalTime > 160000 - 1000) {
                this.$router.replace({
                  path: '/complete',
                });
              // } else {
              //   this.$router.replace({
              //     path: '/relax',
              //     query: {
              //       time: 160000 - this.totalTime,
              //       path: '/complete',
              //     },
              //   });
              // }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  created() {
    if (this.$route.query.practice) {
      this.isPractice = false;
    }
    if (this.isPractice) {
      if (window.screen.width > 1900) {
        practiceData.forEach((item) => {
          item.x = item.x * 1.3;
          item.y = item.y * 1.3;
        });
      }
      this.nodeList = practiceData;
      this.timer2 = setTimeout(() => {
        this.finish();
      }, 25000);
    } else {
      this.sub = this.$util.getRandomInt(1, 5);
      this.formalData = formalData[this.sub - 1];
      if (window.screen.width > 1900) {
        this.formalData.forEach((item) => {
          item.x = item.x * 1.3;
          item.y = item.y * 1.3;
        });
      }
      this.nodeList = this.formalData;
      this.timer2 = setTimeout(() => {
        this.finish();
      }, 160000);
    }
    //随机位置
    // let randomSite = this.getRandomArr(this.siteArr, this.nodeList.length);
    // this.nodeList.forEach((item, index) => {
    //   item.x = randomSite[index].x;
    //   item.y = randomSite[index].y;
    // });
  },
  beforeDestroy() {
    clearTimeout(this.timer1);
    clearTimeout(this.timer2);
  },
  mounted() {
    this.startTimeTotal = this.$util.getTimestamp();
    this.showPlumb();
    window.addEventListener('resize', () => {
      this.jsPlumb.repaintEverything();
    });
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1900px) {
  .faceDelay {
    display: table-cell;
    vertical-align: middle;
    user-select: none;
    .container {
      width: 1040px;
      height: 680px;
      .nodes {
        width: 100%;
        height: 680px;
        // padding-top: 10px;
        overflow: hidden;
        position: relative;
        // cursor: url('http://upload.jahr.janjk.com/hand.ico'), pointer;
        .nodeItem {
          width: 80px;
          height: 80px;
          line-height: 80px;
          text-align: center;
          // background: lightgray;
          background-image: url('../../assets/image/face/link1.png');
          background-size: cover;
          background-repeat: no-repeat;
          border-radius: 100%;
          display: inline-block;
          position: absolute;
          .nodeName {
            font-size: 34px;
            font-family: 'SYHeiBold';
            color: #ffffff;
            text-shadow: 0px 1.95122px 0px #7a3398;
          }
        }
        .connection {
          // background: gray;
          cursor: url('http://upload.jahr.janjk.com/hand.ico'), pointer;
          background-image: url('../../assets/image/face/link2.png');
          .nodeName {
            text-shadow: 0px 1.95122px 0px #ff8b1e;
          }
        }
        .error {
          // box-shadow: 0px 0px 10px rgba(247, 39, 39, 0.5);
          background-image: url('../../assets/image/face/link3.png');
          .nodeName {
            text-shadow: 0px 1.95122px 0px #e12121;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1900px) {
  .faceDelay {
    display: table-cell;
    vertical-align: middle;
    user-select: none;
    .container {
      width: 1352px;
      height: 884px;
      .nodes {
        width: 100%;
        height: 884px;
        // padding-top: 10px;
        overflow: hidden;
        position: relative;
        // cursor: url('http://upload.jahr.janjk.com/hand.ico'), pointer;
        .nodeItem {
          width: 104px;
          height: 104px;
          line-height: 104px;
          text-align: center;
          // background: lightgray;
          background-image: url('../../assets/image/face/link1.png');
          background-size: cover;
          background-repeat: no-repeat;
          border-radius: 100%;
          display: inline-block;
          position: absolute;
          .nodeName {
            font-size: 44px;
            font-family: 'SYHeiBold';
            color: #ffffff;
            text-shadow: 0px 1.95122px 0px #7a3398;
          }
        }
        .connection {
          // background: gray;
          cursor: url('http://upload.jahr.janjk.com/hand.ico'), pointer;
          background-image: url('../../assets/image/face/link2.png');
          .nodeName {
            text-shadow: 0px 1.95122px 0px #ff8b1e;
          }
        }
        .error {
          // box-shadow: 0px 0px 10px rgba(247, 39, 39, 0.5);
          background-image: url('../../assets/image/face/link3.png');
          .nodeName {
            text-shadow: 0px 1.95122px 0px #e12121;
          }
        }
      }
    }
  }
}
</style>
